import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
@Component({
  selector: 'ushg-table-actions',
  templateUrl: './ushg-table-actions.component.html',
  styleUrls: ['./ushg-table-actions.component.scss'],
})
export class UshgTableActionsComponent {
  @Input() actions: any[] = [];
  @Input() ref;
  @Input() index: number;
  @Output() actionTriggered = new EventEmitter();
  filterActions: MenuItem[];
  @Input() disabled: boolean;

  constructor(private cd: ChangeDetectorRef) { 
    console.log(this.ref, '222222222222');
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
    this.filterActions = this.actions?.map((order) => {
      const { label } = order;
      return {
        label: label,
        id: label,
        disabled: order.disable,
        command: (e) => {
          this.actionsEvent(order.onClick);
        },
      };
    });

  }

  actionsEvent(callback: Function) {
    callback(this.ref);
  }
}

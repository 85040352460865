import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TrackApsFormLabels } from 'src/app/aps/models/enums';
import { TrackApsHeaders } from './track-aps-config';
import { HeadersList } from 'src/app/aps/models/application-model';
import { ApsApiService } from 'src/app/aps/services/aps-api-service';
import { Observable } from 'rxjs';
import { Permission } from 'src/app/aps/models/permission';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';
import { TrackApplicationViewComponent } from '../track-application-view/track-application-view.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-track-aps',
  templateUrl: './track-aps.component.html',
  styleUrls: ['./track-aps.component.scss']
})
export class TrackApsComponent implements OnInit {
  trackApsForm: UntypedFormGroup;
  today: Date;
  StatusFilterTrackAPS = StatusFilter;
  headersList: HeadersList[] = TrackApsHeaders(this);
  trackApsApplications$: Observable<any[]>;
  trackApsApplications = [];
  get TrackApsFormLabel(): typeof TrackApsFormLabels {
    return TrackApsFormLabels;
  };
  searchTrackApplications = {} as any;
  showSpinner = false;
  currentPage = 0;
  pageNumber = 1;
  totalItems: number;

  constructor(private apsApiService: ApsApiService,
     private dialogService: UshgDialogService,
     private cdr: ChangeDetectorRef,
     private datePipe: DatePipe) { }

   ngOnInit() {
    this.trackApsForm = new UntypedFormGroup(
      {
        applicationId: new UntypedFormControl(
          null,
          Validators.pattern(/^-?(0|[1-9]\d*)?$/)
        ),
        name: new UntypedFormControl(''),
        fromDt: new UntypedFormControl(''),
        toDt: new UntypedFormControl(''),
        controlNumber: new UntypedFormControl(null),
        statusID: new UntypedFormControl(0),
      },
      this.atLeastOneInputHasValue()
    );
    this.trackApsForm.valueChanges.subscribe((resp) => {
      this.atLeastOneInputHasValue();
    });
    this.searchApplications(true, this.pageNumber);
  }

  atLeastOneInputHasValue = () => {
    return (group: UntypedFormGroup) => {
      if (
        !Object.values(group.value).find(
          (value) => value !== '' && value !== null
        )
      ) {
        return { message: 'Please Enter at least one input value' };
      }
      return null;
    };
  };

  searchApplications(onload?, pageNumber?) {
    const formData = this.trackApsForm.value;
    const searchData: SearchApplication = {
      name: formData.name || '',
      appID: Number(formData.applicationId) || 0,
      controlNo: formData.controlNumber || '',
      fromDate: formData.fromDt || '',
      toDate: formData.toDt || '',
      statusID: Number(formData.statusID) || null,
      pageNumber: (typeof pageNumber?.page === 'number') ? pageNumber.page + 1 : 1,
      pageSize: 10
    };
    this.search(searchData, onload);
  }

  search(filters: SearchApplication, onload?) {
    this.trackApsApplications = [];
    this.showSpinner = true;
    if (this.trackApsForm.valid || onload) {
        this.apsApiService.trackApplications(filters).subscribe((res) => {
          this.showSpinner = false;
          this.searchTrackApplications = res;
          this.totalItems = res.totalCount;
          console.log(this.totalItems);
        if (res.data.length > 0) {
          this.trackApsApplications = res.data.sort((a, b) => {
            const dateA = +new Date(a.requestDate).getTime();
            const dateB = +new Date(b.requestDate).getTime();
            return dateB - dateA
          }).map(r => {
            r.name = r.firstName + ' ' + r.lastName;
            r.requestDate = this.datePipe.transform(r.requestDate, 'MM/dd/yyyy');
            r.workPhone = r.workPhone ? r.workPhone : 'NA';
            return r;
          });
        } else {
          this.trackApsApplications = [];
        }
      });
    }
  }

  paginate(pageNo: any) {
    this.pageNumber = pageNo;
    this.searchApplications(true, pageNo);
  }

   viewActionEventHandler(data) {
    setTimeout(() => {
      this.dialogService.open(TrackApplicationViewComponent, {
        ...data,
      });
    }, 100)

  }

  reset() {
    this.trackApsForm.reset();
    this.currentPage = -1;
    setTimeout(() => {
      this.currentPage = 0;
      this.searchApplications(true);
    }, 0);
  }

}


interface SearchApplication {
  name: string;
  appID: number;
  controlNo: string;
  fromDate: string;
  toDate: string;
  statusID: number;
  pageNumber: number;
  pageSize: number;
}

 const StatusFilter = [
  {
    name: 'All',
    value: null
  },
  {
    name: 'Open',
    value: 1
  },
  {
    name: 'Cancelled',
    value: 2
  },
  {
    name: 'Closed',
    value: 3
  },
  {
    name: 'Completed',
    value: 4
  },
  {
    name: 'Reopen',
    value: 5
  },
];

 
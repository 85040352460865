export enum ApplicationStatus {
  'Initial State' = 10,
  'VCall Scheduled' = 100,
  'VCall Started' = 110,
  'VCall Completed' = 120,
  'Recall Created' = 130,
  'Recall Completed' = 140,
  'With UnderWriting Team' = 200,
  'With APS Team' = 300,
  'APS OrderQueue' = 310,
  'APS Pending' = 320,
  'FollowUp Queue' = 330,
  'APS Returned' = 210,
  'Routed To Issue' = 400,
}

export enum ColumnType {
  aplicationProcessState = 'aplicationProcessState',
  primaryName = 'PrimaryName',
  appId = 'appId',
  timeInQueue = 'timeInQueue',
  general = 'general',
  aps_notes = 'aps_notes',
  uw_notes = 'uw_notes',
  date = 'date',
  requestType = 'requestType',
  applicationStatus = 'applicationStatus',
  actions = 'actions',
  assignedTo = 'assignedTo',
  documents = 'documents',
  orderStatusId = 'orderStatusId',
  OrderRequestType = 'OrderRequestType',
  information = 'information',
  auditParameter = 'auditParameter',
  orderTrackerId = 'orderTrackerId',
  dateTimeCst = 'dateTimeCst',
  index = 'index',
  amount = 'amount',
  reminderDate = 'reminderDate',
  select = 'Select',
  tools = 'tools',
  DATE_WITHOUT_CONVERSION = 'dateWithoutConversion',
  DATE_ONLY = 'dateOnly',
  uwReason = 'uwReason',
  View = 'View'
}

export enum RequestTypeStatus {
  'Requested' = 0,
  'Not_Started' = 1,
  'OrderCompleted' = 2,
  'Order_Queue' = 310,
  'Pending' = 320,
  'APSPending_FollowUp' = 330,
}

export enum NavigationStepStatus {
  COMPLETED = 'completed',
  ACTIVE = 'active',
  INCOMPLETED = 'incompleted',
}

export enum OrderType {
  LAB_AE = 'LAB_AE',
  LAB_OE = 'LAB_OE',
  APS = 'APS',
  LAB_AE_HTWTBP = 'LAB_AE_HTWTBP',
}

export enum OrderStatus {
  Requested = 0,
  Inprogress = 1,
  Created = 2,
  Pending = 3,
  CloseOut = 4,
  Completed = 5,
  Cancelled = 6,
  Withdrawal = 7,
  Reopen = 8,
}

export enum TemplateTitle {
  REQUESTLETTER = 'REQUEST_LETTER',
  AUTHORIZATION = 'AUTHORIZATION',
  AUTHORIZATION_WITHOUT_E_SIGNATURE = 'AUTHORIZATION_WITHOUT_ESIGNATURE',
  LABLETTER = 'EBU_LAB_LETTER',
  HTWTBP = 'BLOOD_PRESSURE_LETTER',
}

export enum DocumentType {
  REQUESTLETTER = 'REQUEST_LETTER',
  AUTHORIZATION = 'AUTHORIZATION',
  LABLETTER = 'EBU_LAB_RECORDS',
  LABRECORDS = 'EBU_LAB_RECORDS',
  APS = 'APS',
  PROVIDER_REQUEST_LETTER = 'PROVIDER_REQUEST_LETTER',
  REQUEST_LETTER = 'REQUEST_LETTER',
  PAYMENT_CHECK = 'PAYMENT_CHECK',
  INVOICE = 'INVOICE',
  OTHER = 'OTHER',
}

export enum NotesEnum {
  orderNotes = 'OrderNotes',
  applicationNotes = 'ApplicationNotes',
}

export enum AuditTypeId {
  ORDER_CREATED = 2,
  ORDER_NOTES = 3,
  APPLICATION_NOTES = 4,
  EMAIL_LOGS = 7,
  DECISION_MATRIX = 9,
  FOLLOWUP_LOG = 10,
  CHECK_REQUEST = null,
  TO_UWS = 11,
  CALL_ANSWERED = 13,
  NO_RESPONSE = 14,
  TO_APS = 15,
}

export enum ApplicationStatusQueryId {
  myQueue = 'applicationStatusId_in: [300, 310, 320]',
  followUp = 'applicationStatusId:330',
  newRequestQueue = 'applicationStatusId:300',
  workQueue = 'applicationStatusId_in: [ 310, 320, 330]',
  followQueue = 'where: { applicationStatusId: 330 } order: {lastFollowUpDate: ASC, id: ASC}',
}

export enum RequestTypes {
  'LAB_AE' = 'LAB AE',
  'LAB_OE' = 'LAB OE',
  'APS' = 'APS',
  'LAB_AE_HTWTBP' = 'HTWTBP',
  'LAB_OE_HTWTBP' = 'HTWTBP',
}

export enum OrderActionType {
  CREATE_ORDER = 'CreateOrder',
  UPDATE_ORDER = 'UpdateOrder',
}

export enum RedirectUrl {
  MY_QUEUE = 'aps/my-queue',
  FOLLOWUP_QUEUE = 'aps/followup-queue',
  APPLICATION_DETAILS = 'aps/application-details',
}

export const ErrorMessages = {
  getOrder: 'Unable to retrieve the Orders for ApplicationId',
  createOrder: 'Unable to Create the Order',
  upsertOrder: 'Unable to Update the Order',
  applicationsQueue:
    'Unable to load the list of applications for logged in user',
  applications: 'Unable to load applicagtions',
  updatetReminderDate: 'Unable to update the reminder date',
  listOfApsUsers: 'Unbale to load the list of aps users',
  application: 'Unable to load the application',
  auditLogByApplicationId: 'Unable to log the audit',
  assignment: 'Unable to assign the application to aps user',
  uploadFiles: 'Unable to upload files',
  upsertProviderDetails: 'Unable to upsert the provider details',
  providerDetails: 'Unable to get the provider details',
  changeTeam: 'Unable to change the Team',
  patchFollowUp: 'Unable to update Followup',
  downloadOrderFiles: 'Unable to download order files',
  insertAuditRecord: 'Unable to insert the audit log record',
  staleDataErrorMessage:
    'Data has been changed outside of this browser, please refresh and try again.',
  defaultErrorMessage:
    'There was an error processing the request. Please refresh and try again',
  searchApplicationNotFound: 'Record not found',
  orderDetailsNotfound: 'There are no APS orders for this application.',
};

export enum SECURE_EMAIL_TEMPLATES {
  SIGNED_AUTHORIZATION = 'Sign Auth',
  LABORATORY = 'LabRequest',
  UNDERWRITING_NEEDS_MORE_INFORMATION = 'UWNeedMoreInformation'
}

export enum FOLLOWUP_ORDER_NOTES_TYPE {
  ANSWERED = 'Answered',
  NO_ANSWER = 'NoAnswer',
  ADD_NOTE = 'AddNote',
  APS = 'APS',
  TO_UWS = 'TO_UWS',
  TO_APS = 'TO_APS',
}

export enum ROUTE_TO_OPTIONS {
  CURRENT_UNDER_WRITER = 'UnderWriting',
  SPECIFIC_UNDER_WRITER = 'UnderWriting',
  ISSUES = 'Issues',
  FOLLOWUP = 'FollowUp',
  CURRENT_UNDER_WRITER_PLACEHOLDER = 'currentUnderWriter',
}

export enum PAGE_STATE {
  MY_QUEUE = 'myQueue',
  FOLLOW_UP = 'followUp',
}

export const COMPANY = {
  FLICA: 'Freedom Life Insurance Company of America',
  NFL: 'National Foundation Life Insurance Company',
  ELIC: 'Enterprise Life Insurance Company',
  SCA: 'Southern Consumers Alliance Enrollment',
  AIBC: 'American Independent Business Coalition',
  ABC: 'American Business Coalition',
};

export enum queriesList {
  name = 'primaryContactName_contains',
  appId = 'applicationId',
  policyNumber = 'policyNumber',
  controlNumber = 'controlNumber',
}
export enum applicationTrackerType {
  PRIMARY = 'Primary',
  ADDON_APPLICATION = 'AddOnApplication',
}
export const SearchApplicationForm = {
  formHeading: 'Search Application',
  applicationNumber: 'Application Number',
  firstName: 'First Name',
  lastName: 'Last Name',
  controlNumber: 'Control Number',
  policyNumber: 'Policy Number',
};
export const PrimaryOrAddonGrid = {
  heading: 'Primary / Addon Application',
};
export const ImportGrid = {
  applicationNotFoundInCosmos:
    'The system is unable to find this application in cosmos',
  importMessage:
    'Please click on the button to import the application from EzAppVCall database.',
};

export const SearchAuthorizationFormLabels = {
  doctorName: 'Doctor Name',
  facilityName: 'Facility Name',
  state: 'State',
  city: 'City',
  zipCode: 'Zipcode',
};

export const CreateAuthorizationFormLabels = {
  doctorfirstName: "Doctor's First Name",
  doctorLastName:"Doctor's Last Name",
  facilityName: 'Facility Name',
  state: 'State',
  city: 'City',
  zipCode: 'Zipcode',
};


export const TrackApsFormLabels = {
  primaryName: 'Primary Name',
  applicationId: 'Application ID',
  controlNumber: 'Control Number',
  fromDate: 'From Date',
  toDate: 'To Date',
  status: 'Status'
}
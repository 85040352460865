import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  ApplicationsForFollowUpQueue,
  AssignedApplicationsForLoggedInUser,

  ClearQuery,
} from 'src/app/store/dispatchers';

@Component({
  selector: 'app-aps-wrapper',
  templateUrl: './aps-wrapper.component.html',
  styleUrls: ['./aps-wrapper.component.scss'],
})
export class ApsWrapperComponent {
  navigationHeaderList = [
    {
      label: 'New Request Queue',
      routerLink: ['new-request-queue'],
      command: () => {
        this.store.dispatch(new ClearQuery());
      },
    },
    {
      label: 'Work Queue',
      routerLink: ['work-queue'],
      command: () => {
        this.store.dispatch(new ClearQuery());
      },
    },
    {
      label: 'My Queue',
      routerLink: ['my-queue'],
      command: () => {
        this.store.dispatch(new ClearQuery());
        this.store.dispatch(new AssignedApplicationsForLoggedInUser());
      },
    },
    {
      label: 'Follow-Up Queue',
      routerLink: ['followup-queue'],
      command: () => {
        this.store.dispatch(new ClearQuery());
        this.store.dispatch(new ApplicationsForFollowUpQueue('', 0, 10));
      },
    },
    {
      label: 'Search Application',
      routerLink: ['search-application'],
      command: () => {
        this.store.dispatch(new ClearQuery());
      },
    },
    {
      label: 'Authorization Forms',
      routerLink: ['authorization-forms'],
    },
    {
      label: 'Track APS',
      routerLink: ['track-aps'],
      command: () => {
        this.store.dispatch(new ClearQuery());
      },
    }


  ];
  constructor(private store: Store) { }

  clearQuery() {
    this.store.dispatch(new ClearQuery());
  }
}

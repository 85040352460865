import { gql } from 'apollo-angular';
import { filter } from 'rxjs/operators';
import { RequestTypeStatus } from '../models/enums';
import {
  sortByLastName,
  sortByType,
  sortBySearchValue,
  handleExpired,
  handleReminderDate,
  handleApplicationStatusId,
  handleAssignedTo,
  handleOrderBy,
  handleState,
  getPageInfo,
} from '../services/graphql-service';

export const listOfApplications = gql`
  query {
    applications(where: { assignedTo: null, applicationStatusId: 300 }) {
      applicationTrackerId: id
      applicationId
      processStatus
      primaryContactName
      agentName
      product
      convertedVersion
      applicationStatusId
      controlNumber
      whenRoutedToTeam
      orderDetails: orderTrackers {
        orderStatusId
        requestType
        applicationId
        createdBy
        reminderDate
        processStatus
      }
      applicationStatus {
        id
      }
      createdBy
      assignedTo
      assignedOn

      createdDate
    }
  }
`;

export function listOfApplicationsForLoggedInApsUser(
  loggedUserName,
  queryId,
  pageNo,
  lastNode?
) {
  const loggedUserNameNoDomain = loggedUserName.replaceAll('\\', '');
  return gql`query {
    applicationsQueue(first: ${pageNo}, ${lastNode ? `${lastNode}` : ''
    } where: { assignedTo_in : ["${loggedUserName}","${loggedUserNameNoDomain}"],${queryId} }) {
  totalCount
  edges {
    node {
      whenRoutedToTeam
      convertedVersion
      id
      applicationId
      reminderDate
      primaryContactName
      applicantFullName: primaryContactName

      agentName
      product
      applicationStatusId
      applicationTrackerType
      controlNumber
      assignmentTrackers {
        assignedTo
      }

      applicationStatus {
        id
        name
        description
      }
      orderTrackers {
        requestType
        applicationId
        orderStatusId
        isActive
        applicationTrackerId
        id
        closeOutStartDate
        withDrawalStartDate
        createdDate
        reminderDate
        processStatus
      }
      applicationStatusHistories {
        id
      }
      createdBy
      assignedTo
      assignedOn
      createdDate
      product
    }
    cursor
  }
  pageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
}
}
`;
}

export function createOrder(request) {
  return gql`
mutation  {
  createOrder(request: ${JSON.stringify(request)}) {
    applicationDetails {
      id
    }
  }
}
`;
}

export const workQueueApplications = gql`
  query {
    applications(where: { applicationStatusId_in: [300, 310, 320] }) {
      controlNumber
      applicationTrackerId: id
      applicationId
      primaryContactName
      agentName
      product
      convertedVersion
      applicationStatusId
      whenRoutedToTeam
      orderDetails: orderTrackers {
        orderStatusId
        requestType
        applicationId
        clientNumber
        applicationTrackerId
        processStatus
      }
      applicationStatus {
        id
      }
      createdBy
      assignedTo
      assignedOn
      createdDate
    }
  }
`;

export function filterAssignedApplications(filters) {
  const a = filters.applications ? filters.applications : filters;
  return gql`query {
    applicationsQueue(${a}
){
        totalCount
        edges {
          node {
            whenRoutedToTeam
            id
            applicationTrackerType
            convertedVersion
            reminderDate
            applicationId
            primaryContactName
            applicantFullName: primaryContactName
            agentName
            product
            applicationStatusId
            controlNumber
            assignmentTrackers {
              assignedTo
            }
            applicationStatus {
              id
              name
              description
            }
            orderTrackers {
              reminderDate
              requestType
              applicationId
              orderStatusId
              isActive
              applicationTrackerId
              id
              closeOutStartDate
              withDrawalStartDate
              createdDate
              processStatus
            }
            applicationStatusHistories {
              id
            }
            createdBy
            assignedTo
            assignedOn
            createdDate
            product
          }
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
  }
`;
}

export const saveReminderDate = gql`
  mutation updateReminderDate($reminderDate: FollowUpReminderDateInput) {
    updateReminderDate(reminderDate: $reminderDate) {
      convertedVersion
      reminderDate
    }
  }
`;
export function listOfApplicationsBasedOnSearch(searchCriteria) {
  const data = searchCriteria?.applications;
  const filters = searchCriteria?.applications.filters;
  const paginate = searchCriteria?.applications.paginate;
  if (filters.assignee) {
    var loggedUserNameNoDomain = filters?.assignee.replaceAll('\\', '');
  }
  const applicationStatusId_lte =
    data.page === 'newRequestQueue'
      ? `applicationStatusId:300,assignedTo:null `
      : '';
  const applicationStatusId_in =
    data.page === 'workQueue' ? `applicationStatusId_in: [ 310, 320, 330]` : '';
  const status = filters.status
    ? `applicationStatusId: ${RequestTypeStatus[filters.status]}`
    : '';
  const appId = filters.appId ? `applicationId: ${Number(filters.appId)}` : '';
  const assignee = filters.assignee
    ? ` assignedTo_in : ["${filters.assignee}","${loggedUserNameNoDomain}"]`
    : '';
  const toDate = filters.toDate
    ? `createdDate_lte:"${new Date(filters.toDate).toISOString()}"`
    : '';
  const fromDate = filters.fromDate
    ? `createdDate_gte:"${new Date(filters.fromDate).toISOString()}"`
    : '';
  const controlNo = filters.controlNo
    ? `controlNumber:"${filters.controlNo}"`
    : '';
  const { pageDirection, pageCursor } = getPageInfo(paginate);

  const combinedFilters = `{${applicationStatusId_lte},${applicationStatusId_in},${status},${appId},${assignee},${toDate},${fromDate},${controlNo}}`;

  return gql`query{
    applicationsQueue(
      ${pageDirection}:10,
      ${pageCursor}
      where :${combinedFilters}){
        totalCount
        edges {
          node {
            applicationTrackerId: id
            applicationId
            controlNumber
            convertedVersion
            primaryContactName
            agentName
            product
            applicationStatusId
            applicationTrackerType
            whenRoutedToTeam
            orderDetails: orderTrackers{
              reminderDate
              orderStatusId
              requestType
              applicationId
              clientNumber
              createdBy
              applicationTrackerId
              processStatus
            }
            applicationStatus
            {
              id
            }
            createdBy
            assignedTo
            assignedOn
            createdDate
          }
          cursor
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
`;
}

export const listOfApsUsersQuery = gql`
  query listOfApsUsers {
    users(groupName: "APSGroups") {
      id
      userPrincipalName
      givenName
      displayName
      businessPhones
      jobTitle
      groupName
    }
  }
`;

export function applicationDetails(appId) {
  return gql` query
    {
      applicationDetailsByApplicationTrackerId(applicationTrackerId:${appId})
      {
        currentUnderWriter 
        family{
          appId
          clientNo
          relationship
          firstName
          lastName
          gender
          middleName
          ssn
          applicantssn:ssn
          birthDate
          birthState
          policyNumber
          birthState
          birthCountry
        }
        applicationId: id
        company
        CompanyName:company
        state
        agent{
          appId
          streetAddress
          agentEmailAdress:email
          firstName
          city
          lastName
        }
        company
        appType
        primaryApplicant{
          occupation
          ssn
          policyNumber
          applicantssn:ssn
          birthState
          birthState
          birthCountry
          gender
          birthDate
          firstName
          middleName
          lastName
        }
        contact{
          applicantHomePhone: homePhone
            applicantmobilePhone:mobilePhone
             mobilePhone
            applicantzipCode:zipCode
            applicantZip:zipCode
            applicantstreetAddress:streetAddress
            contactState:state
            applicantCity:city
            applicantEmailAddress:email
            applicantEmail:email
          }
        policyInformation {
          policyNumber
          appTypeId
        }
        applicationTracker {
          applicationPropertyBags {
            key
            value
          }
          id
          dueDate
          convertedVersion
          applicationStatusId
          createdBy
          assignedTo
          assignedOn
          createdDate
          applicationStatusId
          product
          controlNumber
        }
        orderDetails { 
          createdDate  
          legacyRequestId
          processStatus
          orderCreatedOn
          state
          agentNotes
          controlNumber
          applicationId
          policyNumber
          properties {
            key
            value
          }
          createdBy
          updatedBy
          applicantOverride{
            appId
            relationship
            height
            policyNumber
             weight
            ssn
            applicantssn:ssn
            gender
            birthDate
            birthState
            birthCountry
            employer
            clientNo
            firstName
            middleName
            lastName
          }
            agentNotes

          contactOverride{
            applicantHomePhone: homePhone
            applicantmobilePhone:mobilePhone
            mobilePhone
            applicantzipCode:zipCode
            applicantZip:zipCode
            applicantstreetAddress:streetAddress
            email
            contactState:state
            applicantState:state
            applicantCity:city
            applicantEmailAddress:email
            applicantEmail:email
          }
          dueFollowUpDateTime
          orderTrackerId
          applicationTrackerId
          id
          orderDocuments{
            documents{
              name
              content
              documentType
              updatedDate
            }
          }
          followUps{ 
            callResponse
      id
      notes
      createdDate
      createdBy
      updatedBy

          }
          orderStatusId
          requestType
          applicationId
          orderStatusId
          clientNumber
          labs {
              name
              notes
              status
              apsNotes

          }
          APS:aps {
            disclosureHealthInformation{
              datesOfTreatmentRequested
              informationToBeDisclosed
              reasonForDisclosure
              authorizationExpiresOn
            }
            notes:apsNotes
            uWSNotes:uwsNotes
            reason
            providerOverride {
              name:firstName
              id
              fullname
              providerName:fullname
              providerFullName:fullname
              facilityName: facilityName
              streetAddress
              providerstreetAddress:streetAddress
              city
              state
              providerState:state
              streetAddress
              fax
              providerFax:fax
              phone
              providerPhone:phone
              specialties
              state
              npi
              phone
              lastVisited
              taxId
              phoneExtension
            zipCode
            }
            provider {
              name:firstName
              id
              fullname
              providerName:fullname
              providerFullName:fullname
              facilityName: facilityName
              streetAddress
              providerstreetAddress:streetAddress
              city
              state
              providerState:state
              streetAddress
              fax
              providerFax:fax
              phone
              providerPhone:phone
              specialties
              state
              npi
              phone
              lastVisited
              taxId
              phoneExtension
            zipCode
            }
          }
        }

        checkDetails{
          id
          applicationTrackerId
          orderTrackerId
          clientNumber
          amount
          checkName
          checkRequestDate
          address
          city
          state          
          description
          zip
        }
      }
    }`;
}

export function auditLogAndNotes(appId) { 
  return gql` query
{
  auditLogsByApplicationTrackerId(applicationTrackerId: ${appId})
  {
    id
    information
    loggedBy
    loggedDate
    auditParameter
    orderTrackerId
    isPinned
auditTypeId
  }
} `;
}

export function assignApplicationToApsUser(data) {
  data.createdBy = data.createdBy.replaceAll('\\', '');
  data.domainUserName = data.domainUserName.replaceAll('\\', '');
  data.updatedBy = data.updatedBy.replaceAll('\\', '');

  return gql`
mutation
{
  assignment(inputApplicationDetails: {
    id: ${data.applicationTrackerId || data.id},
    applicationId: ${data.applicationId},
    assignedTo: "${data.domainUserName}",
    assignedOn: "${new Date().toISOString()}",
    updatedBy: "${data.updatedBy}",
    updatedDate: "${new Date().toISOString()}",
    createdDate: "${data.createdDate}",
    createdBy: "${data.createdBy}"
    applicationStatusId: ${data.applicationStatusId}
    convertedVersion: "${data.convertedVersion}"
}
)
{
  applicationDetails
  {
    id
  }
}
}
`;
}

export function savePdfQuery(pdfDetails, documents) {
  return gql`
mutation
{
  uploadFiles(orderDocuments:
    {

      appId: "${pdfDetails.applicationId}"
      orderId: "${pdfDetails.orderTrackerId}"
      clientNumber: "${pdfDetails.clientNumber}"
      documents: [{
        name: "${documents[0].name}"
        content: "${documents[0].content}"
        attributes: ${documents[0].attributes}
        documentType:"${documents[0].documentType}"
        updatedDate: "${documents[0].updatedDate}"
      }
      ]
    })
{
  appId
  documents
  {
    eTag
    content
    documentType
    name
    updatedDate
  }


}
}
`;
}

export const updateProviderPhoneExtension = gql`
  mutation upsertProviderPhoneExtension($providerPhoneExtension: String) {
    upsertProviderPhoneExtension(request: $providerPhoneExtension) {
      orderTrackerId
      convertedVersion
      aps{
        provider{
          phoneExtension
        }
        providerOverride{
          phoneExtension
        }
      }
    }
  }
`;

export const updateProviderCommunicationPreferences = gql`
  mutation upsertProviderDetails($provider: ProviderInput) {
    upsertProviderDetails(provider: $provider) {
      communicationPreferences {
        communicationMode
        electronicSignature
        creditCardPayments
        trunAroungTime
        specialRequestLetter
        lastModifiedDate
        lastModifiedBy
      }
      documents {
        name
        content
        documentType
        updatedDate
      }
    }
  }
`;

export function getproviderDetails(id) {
  return gql`query{
  providerDetails(id: "${id}"){
    communicationPreferences{
      communicationMode
      electronicSignature
      creditCardPayments
      trunAroungTime
      specialRequestLetter
      lastModifiedDate
      lastModifiedBy
    }
    documents{
      name
      content
      documentType
      updatedDate
    }
  }
}
`;
}

export const routeTo = gql`
  mutation changeTeam($request: String) {
    changeTeam(request: $request) {
      applicationDetails {
        id
      }
    }
  }
`;

export const followUp = gql`
  mutation patchFollowUp($followUp: FollowUpInput) {
    patchFollowUp(followUp: $followUp) {
      id
      createdDate
    }
  }
`;

export const lockApplication = gql`
  mutation upsertApplicationProperties($request: String) {
    upsertApplicationProperties(request: $request) {
      id
      applicationId
      applicationPropertyBags{
        key
        value
      }
    }
  }
`;

export function listOfApplicationsForInprogress(searchCriteria) {
  return gql`query{
  applications(where : ${searchCriteria}){
    applicationId
    primaryContactName
    convertedVersion
    applicationTrackerId:id
    agentName
    product
    applicationStatusId
    whenRoutedToTeam
    orderDetails: orderTrackers{
      processStatus
      orderStatusId
      reminderDate
      applicationTrackerId
      requestType
      applicationId
      clientNumber
      createdBy
    }
    applicationStatus
    {
      id
    }
    createdBy
    assignedTo
    assignedOn
    createdDate
    updatedDate
  }
}
`;
}

export function listOfApplicationsForFollowUpQueue(q) {
  q.query = q.query || `where: { applicationStatusId: 330 }
  order: { lastFollowUpDate: ASC, id: ASC }`
  return gql`
  query {
    applicationsQueueWithOffSetPaging(
      skip: ${q.skipValue} 
      take: 10
 ${q.query}) {
      totalCount
      items {
        applicationId
        applicationPropertyBags {
          key
          value
        }
        whenRoutedToTeam
        id
        applicationTrackerType
        convertedVersion
        reminderDate
        applicationId
        primaryContactName
        applicantFullName: primaryContactName
        agentName
        product
        applicationStatusId
        controlNumber
        assignmentTrackers {
          assignedTo
        }
        applicationStatus {
          id
          name
          description
        }
        orderTrackers {
          reminderDate
          requestType
          applicationId
          orderStatusId
          isActive
          applicationTrackerId
          id
          closeOutStartDate
          withDrawalStartDate
          createdDate
          processStatus
        }
        applicationStatusHistories {
          id
        }
        createdBy
        assignedTo
        assignedOn
        createdDate
        product
      }
    }
  }
`
}

export const downloadDocuments = gql`
  query downloadOrderFiles($orderDocumentDetails: String) {
    downloadOrderFiles(orderDocuments: $orderDocumentDetails) {
      documents {
        name
        content
      }
    }
  }
`;

export const insertAuditRecord = gql`
  mutation insertAuditRecord($auditLogRecord: AuditInput) {
    insertAuditRecord(auditLogRecord: $auditLogRecord) {
      id
      convertedVersion
      applicationTrackerId
      skipEmail
    }
  }
`;

export const toggleAuditRecordPin = gql`
  mutation toggleAuditRecordPin($request: String) {
    toggleAuditRecordPin(request: $request) {
      id
    }
  }
`;

export const updateOrder = gql`
  mutation upsertOrder($request: String, $action: String) {
    upsertOrder(request: $request, action: $action) {
      applicationDetails {
        id
        convertedVersion
      }
      inputOrderDetails {
        skipEmail
      }
    }
  }
`;

export const getListOfUnderwWitersQuery = gql`
  query listOfApsUsers {
    users(groupName: "UWGroups") {
      id
      givenName
      userPrincipalName
      displayName
      businessPhones
      jobTitle
      groupName
    }
  }
`;

export const getUsersWorkload = gql`
  query {
    usersAggregrationData {
      assignedTo
      applicationStatusId
      count
    }
  }
`;
export const updateOrderProcessStatus = gql`
  mutation updateOrderProcessStatus($request: String) {
    updateOrderProcessStatus(request: $request) {
      id
      convertedVersion
    }
  }
`;

export function applicationDetailsByApplicationId(applicationId) {
  return gql` query
    {
      application(appId:${applicationId})
      {
        currentUnderWriter
        family{
          appId
          clientNo
          relationship
          firstName
          lastName
          gender
          middleName
          ssn
          applicantssn:ssn
          birthDate
          birthState
          policyNumber
          birthState
          birthCountry
          
        }
    applicationId: id
        company
        CompanyName:company
        state
        agent{
          appId
          streetAddress
          agentEmailAdress:email
          firstName
          city
        }
        company
        appType
        primaryApplicant{
          occupation
          ssn
          policyNumber
          applicantssn:ssn
          birthState
          birthState
          birthCountry
          gender
          birthDate
          firstName
          middleName
          lastName
        }
        contact{
          applicantHomePhone: homePhone
            applicantmobilePhone:mobilePhone
         mobilePhone
            applicantzipCode:zipCode
            applicantZip:zipCode
            applicantstreetAddress:streetAddress
            
            applicantCity:city
            applicantEmailAddress:email
            applicantEmail:email
          }
        policyInformation {
          policyNumber
          appTypeId
        }
        applicationTracker { 
          id
          dueDate
          convertedVersion
          applicationStatusId
          createdBy
          assignedTo
          assignedOn
          createdDate
          applicationStatusId
          product
          controlNumber
          applicationTrackerType
          primaryContactName
         }
        orderDetails {  
          legacyRequestId
          processStatus
          orderCreatedOn
          state
          agentNotes
          controlNumber
          convertedVersion
          applicationId
          
          policyNumber
          applicantOverride{
            appId
            relationship
            height
            policyNumber
             weight
            ssn
            applicantssn:ssn
            gender
            birthDate
            birthState
            birthCountry
            employer
            clientNo
            firstName
            middleName
            lastName
          }
            agentNotes

          contactOverride{
            applicantHomePhone: homePhone
            applicantmobilePhone:mobilePhone
         mobilePhone
            applicantzipCode:zipCode
            applicantZip:zipCode
            applicantstreetAddress:streetAddress
            email
            applicantCity:city
            applicantEmailAddress:email
            applicantEmail:email
          }
          dueFollowUpDateTime
          orderTrackerId
          applicationTrackerId
          id
          orderDocuments{
            documents{
              name
              content
              documentType
              updatedDate
            }
          }
          followUps{ 
            callResponse
      id
      notes
      createdDate
      createdBy
      updatedBy

          }
          orderStatusId
          requestType
          applicationId
          orderStatusId
          clientNumber
          labs {
              name
              notes
              status
          }
          APS:aps {
            disclosureHealthInformation{
              datesOfTreatmentRequested
              informationToBeDisclosed
              reasonForDisclosure
              authorizationExpiresOn
            }
            notes:apsNotes
            uWSNotes:uwsNotes
            reason
            provider {
              name:firstName
              id
              fullname
              providerName:fullname
              providerFullName:fullname
              facilityName: facilityName
              streetAddress
              providerstreetAddress:streetAddress
              city
              state
              providerState:state
              streetAddress
              fax
              providerFax:fax
              phone
              providerPhone:phone
              specialties
              state
              npi
              phone
              lastVisited
              taxId
              phoneExtension
            zipCode
            }
          }
        }
        

        checkDetails{
          id
          applicationTrackerId
          orderTrackerId
          clientNumber
          amount
          checkName
          checkRequestDate
          address
          city
          state          
          description
          zip
        }
      }
    }`;
}

export function auditLogs(fromDate, toDate) {
  return gql` 
  query
    {
      auditLogs(where: {auditTypeId_in: [13,14], loggedDate_gte: "${fromDate}", loggedDate_lte: "${toDate}"}
        )
      {
        id
        applicationTrackerId
        orderTrackerId
        auditTypeId
        auditParameter
        loggedBy
        loggedDate
        applicationTracker {
          id
          applicationId
        }
      }
    } `;
}

export function orders(fromDate, toDate) {
  return gql` query
  	{
      orders(where: 
      {
        orderStatusHistories_some: { createdDate_gte: "${fromDate}", createdDate_lte: "${toDate}"}
      })
      {
        id
        applicationId
        orderStatusId
        createdDate
        requestType
        createdBy
        updatedBy
        orderStatusHistories {
          createdDate
          orderStatusId
          createdBy
          orderTrackerId
        }
      }
 } `;
}

export const upsertOrderProperties = gql`
  mutation upsertOrderProperties($request: String) {
    upsertOrderProperties(
      request: $request
    ) {
      orderTrackerId
      convertedVersion
      properties {
        key
        value
      }
    }
  }
  `;

export function applicationStatusDetails(appId) {
  return gql` query
  {
    applicationDetailsByApplicationTrackerId(applicationTrackerId:${appId})
    {
      currentUnderWriter
      family{
        appId
        clientNo
        relationship
        firstName
        lastName
        gender
        middleName
        ssn
        applicantssn:ssn
        birthDate
        birthState
        policyNumber
        birthState
        birthCountry
        height
        weight
      }
      applicationId: id
      company
      CompanyName:company
      state
      agent{
        appId
        streetAddress
        agentEmailAdress:email
        firstName
        city
        lastName
        agentNumbers
      }
      payment {
        appId
        accountNumber
        addressOnCheck
        bankAccountNumber
        bankAcctABusiness
        bankName
        bankRoutingCode
        bdAccountNumber
        billingAddress
        billingCity
        billingName
        billingState
        billingZip
        cityOnCheck
        commonPayer
        commonPayerAgent
        commonPayerPolicyNo
        creditCardType
        debitsBeginDate
        expirationDate
        iniPmtCCBillingPhone
        initPmtAccountNumber
        initPmtBDAccountNumber
        initPmtBDNoDigits
        initPmtBankAccountNumber
        initPmtBankAcctABusiness
        initPmtBankAddressOnCheck
        initPmtBankCityOnCheck
        initPmtBankName
        initPmtBankNameOnCheck
        initPmtBankPhoneOnCheck
        initPmtBankRoutingCode
        initPmtBankStateOnCheck
        initPmtBankZipOnCheck
        initPmtBillingAddress
        initPmtBillingCity
        initPmtBillingName
        initPmtBillingState
        initPmtBillingZip
        initPmtCCAccountNumber
        initPmtCCNoDigits
        initPmtCreditCardType
        initPmtExpirationDate
        initialPaymentBy
        methodOfPayment
        modeOfPayment
        nameOnCheck
        nextOrSpecificDate
        phoneOnCheck
        premiumDraftSum
        requestedEffectiveDate
        stateOnCheck
        zipOnCheck
      }
      company
      appType
      primaryApplicant{
        occupation
        ssn
        policyNumber
        applicantssn:ssn
        birthState
        clientNo
        birthCountry
        gender
        birthDate
        firstName
        middleName
        lastName
        height
        weight
      }
      contact{
        applicantHomePhone: homePhone
          applicantmobilePhone:mobilePhone
          mobilePhone
          applicantzipCode:zipCode
          applicantZip:zipCode
          applicantstreetAddress:streetAddress
          contactState:state
          applicantCity:city
          applicantEmailAddress:email
          applicantEmail:email
        }
      policyInformation {
        policyNumber
        appTypeId
        product
      }
      applicationTracker {
        applicationPropertyBags {
          key
          value
        }
        id
        dueDate
        convertedVersion
        applicationStatusId
        createdBy
        assignedTo
        assignedOn
        createdDate
        applicationStatusId
        product
        controlNumber
      }
      orderDetails { 
        createdDate  
        legacyRequestId
        processStatus
        orderCreatedOn
        state
        agentNotes
        controlNumber
        applicationId
        policyNumber
        properties {
          key
          value
        }
        createdBy
        updatedBy
        applicantOverride{
          appId
          relationship
          height
          policyNumber
            weight
          ssn
          applicantssn:ssn
          gender
          birthDate
          birthState
          birthCountry
          employer
          clientNo
          firstName
          middleName
          lastName
        }
          agentNotes

        contactOverride{
          applicantHomePhone: homePhone
          applicantmobilePhone:mobilePhone
          mobilePhone
          applicantzipCode:zipCode
          applicantZip:zipCode
          applicantstreetAddress:streetAddress
          email
          contactState:state
          applicantState:state
          applicantCity:city
          applicantEmailAddress:email
          applicantEmail:email
        }
        dueFollowUpDateTime
        orderTrackerId
        applicationTrackerId
        id
        followUps{ 
          callResponse
          id
          notes
          createdDate
          createdBy
          updatedBy
        }
        orderStatusId
        requestType
        applicationId
        orderStatusId
        clientNumber
        labs {
            name
            notes
            status
            apsNotes:apsNotes
        }
        APS:aps {
          disclosureHealthInformation{
            datesOfTreatmentRequested
            informationToBeDisclosed
            reasonForDisclosure
            authorizationExpiresOn
          }
          notes:apsNotes
          uWSNotes:uwsNotes
          reason
          providerOverride {
            name:firstName
            id
            fullname
            providerName:fullname
            providerFullName:fullname
            facilityName: facilityName
            streetAddress
            providerstreetAddress:streetAddress
            city
            state
            providerState:state
            streetAddress
            fax
            providerFax:fax
            phone
            providerPhone:phone
            specialties
            state
            npi
            phone
            lastVisited
            taxId
            phoneExtension
            zipCode
          }
          provider {
            name:firstName
            id
            fullname
            providerName:fullname
            providerFullName:fullname
            facilityName: facilityName
            streetAddress
            providerstreetAddress:streetAddress
            city
            state
            providerState:state
            streetAddress
            fax
            providerFax:fax
            phone
            providerPhone:phone
            specialties
            state
            npi
            phone
            lastVisited
            taxId
            phoneExtension
            zipCode
          }
        }
      }
    }
  }`;
}

export function applicationStatusHistories(fromDate, toDate){
  return gql` query {
    applicationStatusHistory(where: 
      {
        createdDate_gte: "${fromDate}", createdDate_lte: "${toDate}"
      })
      {
        id
        applicationId
        applicationTrackerId
        createdBy
        createdDate
        applicationStatusId
        assignedTo
      }
  }`;
}

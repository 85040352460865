import { ColumnType } from 'src/app/aps/models/enums';

export const SearchApplicationHeadersList = (This) => {
  return [
    { label: '#', field: '', type: ColumnType.index },
    {
      label: 'Applicant',
      field: 'applicantFullName',
      subField: 'applicationId',
    },
    { label: 'Product', field: 'appType' },
    { label: 'Company', field: 'company' },
    { label: 'State', field: 'state' },
    {
      label: 'EzApp Created Date',
      field: 'createDate',
      type: ColumnType.DATE_WITHOUT_CONVERSION,
    },
    {
      label: 'Select',
      field: 'select',
      type: ColumnType.select,
      onChange: (applicationData) => {
        return This.getApplicationTrackerDetails(applicationData);
      },
    },
  ];
};

export const SearchApplicationSubHeaders = [
  { label: '#', field: '', type: ColumnType.index },
  { label: 'Applicant', field: 'applicantFullName' },
  {
    label: 'Created Date',
    field: 'createdDate',
    type: ColumnType.DATE_WITHOUT_CONVERSION,
  },
  { label: 'Type', field: 'type' },
  { label: 'Action', field: '', type: ColumnType.actions },
];


